'use strict';


var Parallax = require('parallax-js');

var Home = function() {
    var context = $('.home');
    var set = $('.stage');
    var stage = $('.stage').get(0);
    var parallaxInstance = new Parallax(stage);
    var scrollBtnNext = $('.home__intro__trigger');
    var transition = false;
    var sections = $('.js-section');

    function toggleParallax() {
        if (transition === true) {
            parallaxInstance.disable();
        } else {
            parallaxInstance.enable();
        }
    }

    function makeTransition() {
        var index = $.scrollify.currentIndex();

        if (index === 0) {
            transition = false;
            set.removeClass('-tween');
            set.removeClass('-ending');
        }

        if (index === 1) {
            transition = true;
            set.removeClass('-ending');
            set.addClass('-tween');
        }

        if (index === 2) {
            transition = true;
            set.removeClass('-tween');
            set.addClass('-ending');
        }
    }

    function setActiveSection() {
        var currentSection = $.scrollify.current();
        currentSection.removeClass('-active');
        sections.addClass('-inactive');
        currentSection.removeClass('-inactive');
        currentSection.addClass('-active');
    }

    if (context.length) {
        $.scrollify({
            section : '.js-section',
            scrollbars: true,
            setHeights: true,
            sectionName : 'section-name',
            touchScroll: true,
            scrollSpeed: 3000,
            before:function() {
                makeTransition();
                setActiveSection()
            },
            after:function() {
                toggleParallax();
            }
        });

        $('a[href="/#arbol-de-deseos"]').on('click', function() {
            $.scrollify.move('#arbol-de-deseos');
        });

        scrollBtnNext.on('click', function() {
            $.scrollify.next();
        });

        setActiveSection();
    }
};

module.exports = Home;
