'use strict';

// Constructor
var WishCard = function() {

    var wishCard = $('.wish-card');

    if(wishCard.length){

        var colors = [
            'rgba(7,189,220,.9)',
            'rgba(5,100,153,.9)',
            'rgba(252,186,8,.9)',
            'rgba(152,5,228,.9)',
            'rgba(165,95,190,.9)',
            'rgba(255,0,0,.9)',
            'rgba(255,154,81,.9)'
        ];
        var close = $('.wish-card__controls__close');
        var prev = $('.wish-card__controls__prev');
        var next = $('.wish-card__controls__next');
        var overlay = $('.wish-card__overlay');

        function findNext() {
            var activeWishCard = $('.wish-card.-open');
            wishCard.removeClass('-open');
            wishCard.parents('li').removeClass('-reset');
            activeWishCard.parents('li')
                          .next('li')
                          .addClass('-reset')
                          .children('.wish-card')
                          .addClass('-open');
            checkControls();
        }

        function findPrev() {
            var activeWishCard = $('.wish-card.-open');
            wishCard.removeClass('-open');
            wishCard.parents('li').removeClass('-reset');
            activeWishCard.parents('li')
                          .prev('li')
                          .addClass('-reset')
                          .children('.wish-card')
                          .addClass('-open');
            checkControls();
        }

        function checkControls() {
            var activeWishCard = $('.wish-card.-open');
            var listPrev = activeWishCard.parents('li').prev();
            var listNext = activeWishCard.parents('li').next();
            if (listPrev.length < 1) {
                prev.addClass('-disabled');
            } else {
                prev.removeClass('-disabled');
            }
            if (listNext.length < 1) {
                next.addClass('-disabled');
            } else {
                next.removeClass('-disabled');
            }
        }

        wishCard.each(function(){
            var $this = $(this);
            var randomColor = colors[Math.floor(Math.random() * colors.length)];


            $this.css('background-color', randomColor);

            $this.on('click', function() {
                $this.parents('li').addClass('-reset');
                overlay.addClass('-active');
                $this.addClass('-open');
                checkControls();
            });
            close.on('click', function(e) {
                $this.removeClass('-open');
                $this.parents('li').removeClass('-reset');
                overlay.removeClass('-active');
                e.stopPropagation();
            });

        });


        next.on('click', function(e) {
            findNext();
            e.stopPropagation();
        });

        prev.on('click', function(e) {
            findPrev();
            e.stopPropagation();
        });
    }
};

module.exports = WishCard;
