'use strict';

var Write = function() {
    var context = $('.write');

    if(context.length) {
        var trigger = $('.write__form__button');
        var formBox = $('.write__box');

        trigger.on('click', function(el) {
            formBox.addClass('-success');
            el.preventDefault();
        });
    }
}

module.exports = Write;
