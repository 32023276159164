// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

global.$ = global.jQuery = require('jquery');
global._ = require('underscore');
var Header = require('../_modules/header/header');
var Slider = require('../_modules/slider/slider');
var WishCard = require('../_modules/wish-card/wish-card');
var Home = require('./home');
var Write = require('./write');

$(function() {
    require('../../bower_components/bootstrap-sass/assets/javascripts/bootstrap.min');
    require('../../bower_components/slick-carousel/slick/slick');
    require('../../bower_components/Scrollify/jquery.scrollify');

    new Header();
    new Slider();
    new Home();
    new WishCard();
    new Write();
});
